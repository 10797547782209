import {Component, inject, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {AppNotificationFrom} from "../../notification.component";
import {
  INotificationsInPostCommentTagNotification
} from "desiren-core-lib/lib/types/notifications/tags/in-post-comment-tag.notification.interface";
import {TranslationsService} from "../../../../../../service/translations/translations.service";

@Component({
  selector: 'app-n-in-post-comment',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './n-in-post-comment.component.html',
  styleUrl: './n-in-post-comment.component.scss'
})
export class NInPostCommentComponent extends AppNotificationFrom {
  @Input('data') declare data: INotificationsInPostCommentTagNotification;
}
