<ng-container *ngTemplateOutlet="outlet == 'list' ? list : toast"></ng-container>
<ng-template #list>
  <div class="notification" #notification>
    <div class="notification_status" [ngClass]="{'isRead': data.isReadByUser}"></div>
    <app-profile-avatar [preview]="false" [isCreator]="data.fromUser.isCreator" (click)="navigateToProfile()" size="chat" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <app-user-name [displayName]="displayName" (click)="navigateToProfile()" [deletedAt]="from?.deletedAt" [nickname]="this.from?.nickname" [kycVerified]="this.from?.isCreator && this.from?.kycVerified"></app-user-name>
      <div class="notification_body-text">
        <app-icons name="heart_fill" width="20" height="20" fillColor="#6A49FA"></app-icons>
        <span>{{translationsService.translate(translationsService.keys.NOTIFICATION_NOTIFICATION_SPAN_SUBSCRIBED_TO_YOUR_PROFILE)}}</span>
      </div>
      <div class="notification_body-date">
        <span class="notification_body-date">{{updateAt}}</span>
        <app-icons (click)="sendMessage(data.fromUser.id)"  name="messages" width="18" height="18" fillColor="#6A49FA"></app-icons>
      </div>
    </div>
    <app-icons (click)="deleteMe()" class="notification_delete" name="trash" width="25" height="25" fillColor="#6A49FA"></app-icons>
  </div>
</ng-template>
<ng-template #toast>
  <div class="notification toast">
    <div class="notification_type">
      <app-icons name="heart_fill" width="20" height="20" fillColor="#6A49FA"></app-icons>
    </div>
    <app-profile-avatar [preview]="false" (click)="navigateToProfile()" size="notification" [url]="fromAvatar?.url"></app-profile-avatar>
    <div class="notification_body">
      <app-user-name (click)="navigateToProfile()" [displayName]="displayName" [deletedAt]="from?.deletedAt" [nickname]="this.from?.nickname" [kycVerified]="this.from?.isCreator && this.from?.kycVerified  && !from?.deletedAt"></app-user-name>
      <div class="notification_body-text">
        <span>{{translationsService.translate(translationsService.keys.NOTIFICATION_NOTIFICATION_SPAN_SUBSCRIBED_TO_YOUR_PROFILE)}}</span>
      </div>
    </div>
    <app-icons name="close_1" class="notification_delete" width="40" height="40" fillColor="#DEDEEB" (click)="onRead.emit(data.id)"></app-icons>
  </div>
</ng-template>
