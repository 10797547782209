import {Component, inject, Input, ViewChild} from '@angular/core';
import {AppNotificationFrom} from "../../notification.component";
import {CommonModule} from "@angular/common";
import {ProfileAvatarComponent} from "../../../../../../uikit/profile/profile-avatar/profile-avatar.component";
import {UserNameComponent} from "../../../../../../uikit/user-name/user-name.component";
import {IconsComponent} from "../../../../../../uikit/icons/icons.component";
import {RouterModule} from "@angular/router";
import {
  INotificationsPostPurchasedNotification
} from "desiren-core-lib/lib/types/notifications/purchases/post-purchased.notification.interface";
import {TranslationsService} from "../../../../../../service/translations/translations.service";

@Component({
  selector: 'app-n-post-purchased',
  standalone: true,
  imports: [
    CommonModule,
    ProfileAvatarComponent,
    UserNameComponent,
    IconsComponent,
    RouterModule,
  ],
  templateUrl: './n-post-purchased.component.html',
  styleUrl: './n-post-purchased.component.scss'
})
export class NPostPurchasedComponent extends AppNotificationFrom {
  @ViewChild('notification') declare notification;

  @Input('data') declare data: INotificationsPostPurchasedNotification;

  get link() {
    return `/profile/${this.myId}/post/${this.data.postId}`;
  }
}
