import {Component, Input} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IconsComponent} from "../icons/icons.component";

@Component({
  selector: 'app-user-name',
  standalone: true,
  imports: [
    CommonModule,
    IconsComponent,
  ],
  templateUrl: './user-name.component.html',
  styleUrl: './user-name.component.scss'
})
export class UserNameComponent {
  @Input() displayName: string = '';
  @Input() nickname: string = '';
  @Input() kycVerified: boolean = false;
  @Input({ required: true }) deletedAt: Date | null = null;
}
